import * as React from 'react';
import { ContentBlock, Typography, Header } from '@/components';
import Papa from 'papaparse';
import {
  Button,
  DropZone,
  Flex,
  Text,
  VisuallyHidden
} from '@aws-amplify/ui-react';
import { createUseStyles } from 'react-jss';
import FileSaver from 'file-saver';
import * as JSZip from 'jszip';
import { toCanvas } from 'qrcode';
import {
  addUserAgreements,
  createCustomer,
  getAgreements,
  getCustomerProvision,
  provisionCustomer
} from '@/api';
import { AppContext } from '@/GlobalProvider/GlobalProvider';
import { convertAgeToDate } from '@/utils';

export const useStyles = createUseStyles({
  header: {
    textAlign: 'center',
    marginBottom: 40,
    '@media (max-width: 576px)': {
      marginBottom: 30
    }
  },
  title: {
    marginBottom: 15
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 25
  },
  dropZone: {
    width: '100%'
  },
  link: {
    color: 'black',
    display: 'block',
    textAlign: 'center'
  }
});

const generateZipFile = (content: string, imgsData) => {
  const zip = new JSZip();
  const currentTime = Date.now();
  zip.file(`account_creation_result_log_${currentTime}.txt`, content);
  imgsData.forEach(({ name, data }) => {
    zip.file(`${name}.png`, data, { base64: true });
  });
  zip.generateAsync({ type: 'blob' }).then(function (content) {
    FileSaver.saveAs(content, `${currentTime}.zip`);
  });
};

type CreateAccountParams = {
  id: string;
  onSuccess: (subjectId: string, userId: string) => void;
  onFail: (message: string) => void;
  data: {
    subjectId: string;
    age: number;
    gender: string;
    height: number;
    weight: number;
  };
};

const createAccount = async ({
  id,
  data,
  onSuccess,
  onFail
}: CreateAccountParams) => {
  if (data.subjectId) {
    if (!data.height || data.height < 40 || data.height > 88) {
      onFail(`${data.subjectId}: bad height value ${data.height}`);
      return;
    }

    if (!data.weight || data.weight < 90 || data.weight > 550) {
      onFail(`${data.subjectId}: bad weight value ${data.weight}`);
      return;
    }

    if (!data.age || data.age < 18 || data.age > 120) {
      onFail(`${data.subjectId}: bad age value ${data.age}`);
      return;
    }

    const newCustomerProvision = await getCustomerProvision(id, data.subjectId);

    if (newCustomerProvision.id) {
      onFail(`${data.subjectId}: account ${data.subjectId} already exists `);
      return;
    }

    const response = await createCustomer(
      { ...data, dateOfBirth: convertAgeToDate(data.age) },
      id
    );
    onSuccess(data.subjectId, response.id);
    await provisionCustomer(response.id, id, data.subjectId);
    const agreementsResponse = await getAgreements(id);
    await addUserAgreements(
      response.id,
      agreementsResponse,
      `${response.firstName} ${response.lastname}`
    );

    return response.id;
  }
};

const acceptedFileTypes = ['.xlsx', '.xls', '.csv'];

type FileType = { data: Array<[string, string, string, string, string]> };

export const PreCreateAccounts = () => {
  const { id } = React.useContext(AppContext);
  const styles = useStyles();
  const [files, setFiles] = React.useState([]);
  const hiddenInput = React.useRef(null);

  const onFilePickerChange = async (e) => {
    if (e.target.files) {
      try {
        const file = e.target.files[0];

        Papa.parse<string[]>(file, {
          worker: true, // use a web worker so that the page doesn't hang up
          async complete({ data }: FileType) {
            const successUserData: Array<{
              subjectId: string;
              userId: string;
            }> = [];
            const failedMessages = [];

            const onSuccess = (subjectId: string, userId: string) => {
              successUserData.push({ subjectId, userId });
            };

            const onFail = (message: string) => {
              failedMessages.push(message);
            };

            for (const [subjectId, height, weight, age, gender] of data.slice(
              1
            )) {
              const data = {
                subjectId,
                age: Number(age),
                gender,
                height: Number(height),
                weight: Number(weight)
              };
              await createAccount({
                onSuccess,
                data,
                id,
                onFail
              });
            }

            const imgsData = [];
            for (const { subjectId, userId } of successUserData) {
              const qrCode = await toCanvas(userId, {
                errorCorrectionLevel: 'H',
                margin: 20
              });
              const ctx = qrCode.getContext('2d');
              ctx.font = '18px Arial';
              ctx.fillText(`Subject Id: ${subjectId}`, 20, 280);

              imgsData.push({
                name: subjectId,
                data: qrCode.toDataURL().replace('data:image/png;base64,', '')
              });
            }

            const successSubjectIdsToString = successUserData
              .map(({ subjectId }) => subjectId)
              .join('\n');
            const failedMessagesToString = failedMessages.join('\n');
            const content = `Accounts Failed Creation\n---------------\n${failedMessagesToString}\n\nAccounts Created\n----------------\n${successSubjectIdsToString}`;
            await generateZipFile(content, imgsData);
          }
        });
        // 6. call the onChange event
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <Header />
      <ContentBlock hasBackButton>
        <div className={styles.header}>
          <Typography className={styles.title} component="h2">
            Pre-Create Accounts
          </Typography>
        </div>
        <div className={styles.content}>
          <DropZone
            className={styles.dropZone}
            acceptedFileTypes={acceptedFileTypes}
            onDropComplete={({ acceptedFiles, rejectedFiles }) => {
              setFiles(acceptedFiles);
            }}
          >
            <Flex direction="column" alignItems="center">
              <Text>Upload pre-created account CSV file</Text>
              <Button size="small" onClick={() => hiddenInput.current.click()}>
                Browse for CSV file
              </Button>
            </Flex>
            <VisuallyHidden>
              <input
                type="file"
                tabIndex={-1}
                ref={hiddenInput}
                onChange={onFilePickerChange}
                multiple={true}
                accept={acceptedFileTypes.join(',')}
              />
            </VisuallyHidden>
          </DropZone>
          {files.map((file) => (
            <Text key={file.name}>{file.name}</Text>
          ))}
        </div>
        <a
          href="/example_pre-create_accounts.csv"
          download
          className={styles.link}
        >
          download an example CSV file
        </a>
      </ContentBlock>
    </>
  );
};
